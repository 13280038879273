import "../scss/style.scss";
import './core/utils/inline-svg.js';
import './core/utils/sliders.js';
import './core/scroll/lazyload.js';
import './core/forms/select.js';
import './core/utils/map.js';
import { formFieldsInit, formSubmit } from './core/forms/forms.js';
import { isTarget, spollers, isWebp, _slideUp, _slideDown, bodyLockStatus, bodyLockToggle, tabs, bodyUnlock, bodyLock } from './core/utils/functions.js';
import { gotoBlock } from './core/scroll/gotoblock.js';

const $html = document.documentElement;
const $fixtips = document.querySelector('[data-fixtips]');
const $anchor = document.querySelector('[data-anchor]');
const $headerMenu = document.querySelector('[data-mobile-menu]');
const $pageBurgerEl = document.querySelector('[data-burger]');
const $filter = document.querySelector('[data-filter]');
const $card = document.querySelector('[data-card]');
const $popup = document.querySelector('.popup');
const $cookies = document.querySelector('[data-cookies]');
const $dataDeliveryPreview = document.querySelector('[data-delivery-preview]');
const $dataDeliveryEdit = document.querySelector('[data-delivery-edit]');
const $businessDeliveryActions = document.querySelectorAll('[data-delivery-action="business"]');
const $deliveryAlternativeCheckbox = document.querySelector('input[data-delivery-alternative-btn]');
const $deliveryAlternative = document.querySelector('[data-delivery-alternative]');

export const popup = {
	open (selector) {
		document.querySelector(selector).classList.add('_open');
		if (bodyLockStatus) {
			bodyLock(0);
		}
	},
	close (e, selector) {
		e.target.closest(selector).closest('.popup').classList.remove('_open');
		if (bodyLockStatus) {
			bodyUnlock(0);
		}
	}
};

const clickOnDocument = (e) => {
	const targetElement = e.target;

	const $pageBurger = isTarget(targetElement, '[data-burger]')
	const toggleMenu = () => {
		if (bodyLockStatus) {
			bodyLockToggle();
			$headerMenu.classList.toggle('js-open');
			$pageBurgerEl && $pageBurgerEl.classList.toggle('js-open');
			$headerMenu.querySelectorAll('[data-sublist]').forEach(sublist => {
				sublist.classList.remove('js-open');
			})
		}
	}

	if ($pageBurger && $headerMenu) {
		toggleMenu();
	}

	const $pageMenuClose = isTarget(targetElement, '[data-menu-close]');
	if ($pageMenuClose) {
		toggleMenu();
	}

	const $nextLevelMenuOpen = isTarget(targetElement, '[data-next-lvl]:not(._open)');
	if ($nextLevelMenuOpen) {
		setTimeout(() => {
			$nextLevelMenuOpen.classList.add('_open');
		}, 500)
		const $sublist = $nextLevelMenuOpen.nextElementSibling;
		if($sublist.hasAttribute('data-sublist')) {
			if (document.querySelector('li._active-li')) {
				document.querySelector('li._active-li').classList.remove('_active-li');
			}
			if (document.querySelector('[data-next-lvl]._open')) {
				document.querySelector('[data-next-lvl]._open').classList.remove('_open');
			}
			if (document.querySelector('.second-lvl.js-open')) {
				_slideUp(document.querySelector('.second-lvl.js-open'));
				document.querySelector('.second-lvl.js-open').classList.remove('js-open');
			} 
			$sublist.classList.add('js-open');
			_slideDown($sublist);
			$nextLevelMenuOpen.closest('li').classList.add('_active-li');
		}
	}

	const $nextLevelMenuClose = isTarget(targetElement, '[data-next-lvl]._open');
	if ($nextLevelMenuClose) {
		const $sublist = $nextLevelMenuClose.nextElementSibling;
		if($sublist.hasAttribute('data-sublist')) {
			$sublist.classList.remove('js-open');
			_slideUp($sublist);
		}
		$nextLevelMenuClose.classList.remove('_open');
		$nextLevelMenuClose.closest('li').classList.remove('_active-li');
	}

	const $prevLevelMenu = isTarget(targetElement, '[data-prev-lvl]');
	if ($prevLevelMenu) {
		$prevLevelMenu.closest('[data-sublist]').classList.remove('js-open');
	}

	if(targetElement.closest('.popup__close')) {
		popup.close(e, '.popup__close');
	}

	if(targetElement.closest('.popup') && !targetElement.closest('.popup__content')) {
		popup.close(e, '.popup');
	}
	
	if(targetElement.closest('[href="#"]')) {
		e.preventDefault();
	}

	const $fixtipBtn = isTarget(targetElement, '[data-fixtip-btn]');
	if ($fixtipBtn) {
		const $fixtip = $fixtipBtn.closest('[data-fixtip]');
		$fixtip && $fixtip.classList.toggle('_active');
	}

	const $anchor = isTarget(targetElement, '[data-anchor]');
	if ($anchor) {
		gotoBlock('.header');
	}

	const $filterBtn = isTarget(targetElement, '[data-filter-btn]');
	if ($filterBtn) {
		if (bodyLockStatus) {
			bodyLock();
		}
		$filter && $filter.classList.add('js-open');
	}

	const $filterClose = isTarget(targetElement, '[data-filter-close]');
	if ($filterClose) {
		if (bodyLockStatus) {
			bodyUnlock();
		}
		$filter && $filter.classList.remove('js-open');
	}

	const $overlay = isTarget(targetElement, '.page-overlay');
	if ($overlay) {
		if (bodyLockStatus) {
			bodyUnlock();
		}

		if($filter && $filter.classList.contains('js-open')) $filter.classList.remove('js-open');
		if($card && $card.classList.contains('js-open')) $card.classList.remove('js-open');
	}

	const $quantityPlus = isTarget(targetElement, '.quantity__button_plus')
	const $quantityMinus = isTarget(targetElement, '.quantity__button_minus')
	if ($quantityPlus) {
		const $quantity = $quantityPlus.closest('.quantity');
		const $quantityInput = $quantityPlus.closest('.quantity').querySelector('input');
		let value = parseInt($quantityInput.value);
		$quantityInput.value = ++value;
		if (value > 1) {
			$quantity.classList.remove('_disabled');
		} else {
			$quantity.classList.add('_disabled');
		}
	}
	if ($quantityMinus) {
		const $quantity = $quantityMinus.closest('.quantity');
		const $quantityInput = $quantityMinus.closest('.quantity').querySelector('input');
		let value = parseInt($quantityInput.value);
		value > 1 ? $quantityInput.value = --value : value;
		if (value > 1) {
			$quantityInput.value = --value;
			$quantity.classList.remove('_disabled');
		} else {
			$quantity.classList.add('_disabled');
		}
	}

	const $cardOpenTrigger = isTarget(targetElement, '[data-card-open]');
	if($cardOpenTrigger) {
		$card && $card.classList.add('js-open');
		if (bodyLockStatus) {
			bodyLock();
		}
	}

	const $cardCloseTrigger = isTarget(targetElement, '[data-card-close]');
	if($cardCloseTrigger) {
		$card && $card.classList.remove('js-open');
		if (bodyLockStatus) {
			bodyUnlock();
		}
	}

	const $popupTriggerOpen = isTarget(targetElement, '[data-popup-open]');
	if($popupTriggerOpen) {
		$popup && popup.open('.popup');
	}

	const $closeCookies = isTarget(targetElement, '[data-cookies-close]');
	if ($closeCookies) {
		if ($cookies) $cookies.style.display = 'none';
		localStorage.setItem('cookies-banner', 'off');
	}

	const $productHeading = isTarget(targetElement, '[data-product-anchor]');
	if ($productHeading) {
		gotoBlock('[data-product-descr]');
	}

	const $formMessageHideBtn = isTarget(targetElement, '[data-form-message-hide]');
	if ($formMessageHideBtn) {
		$formMessageHideBtn.closest('[data-form-message]').classList.remove('_show');
	}
};

const setCopyrightYear = (el) => {
	el ? el.innerHTML = `${new Date().getFullYear()}` : null;
};

const searchProducts = (form) => {
	const $formInput = form.querySelector('[data-search-input]');
	const $formClear = form.querySelector('[data-search-clear]');
	const $formDropbox = form.querySelector('[data-search-dropbox]');
	const $formResults = form.querySelectorAll('[data-search-result]');

	if($formDropbox) $formDropbox.style.display = 'none';

	if ($formClear) {
		$formClear.onclick = () => {
			if($formInput) $formInput.value = '';
			if($formDropbox) $formDropbox.style.display = 'none';
			form.classList.remove('_search');
			form.classList.remove('_br');
		};
	}

	const inputText = (e) => {
		const searchText = e.target.value.toLowerCase();
		form.classList.add('_search');

		$formResults && $formResults.forEach(text => {
			const resultText = text.textContent.toLowerCase();
			text.closest('li').style.display = 'none';

			if (resultText.includes(searchText)) {
				$formDropbox.style.display = '';
				text.closest('li').style.display = '';
				form.classList.add('_br');
			}
		});

		if (!e.target.value) {
			$formDropbox.style.display = 'none';
			form.classList.remove('_search');
			form.classList.remove('_br');
		};
	};

	$formInput && $formInput.addEventListener('input', inputText);
};

const setParallaxOnImage = (e) => {
	const $image = e.target.closest('[data-parallax]');
	const $parallaxImage = $image.querySelector('[data-parallax-image]');
	const $parallaxBorder = $image.querySelector('[data-parallax-border]');

	const x = (-window.innerWidth / e.pageX*5);
	const y = (window.innerHeight / e.pageY*5) * 10;

	const notFar = Math.abs(x) < 50;

	if ($parallaxImage && notFar) $parallaxImage.style.transform = `translate(${x}px, ${y}px)`;
	if ($parallaxBorder && notFar) $parallaxBorder.style.transform = `translate(${x*1.5}px, ${y*1.5}px)`;
};

const setWindowScroll = () => {
	if(window.scrollY > 50) {
		$fixtips && $fixtips.classList.add('_show');
	} else {
		$fixtips && $fixtips.classList.remove('_show');
		$fixtips.querySelector('[data-fixtip]') && $fixtips.querySelector('[data-fixtip]').classList.remove('_active');
	}

	if($anchor) $anchor.style.opacity = (window.scrollY + window.innerHeight) / document.body.scrollHeight;
};

const resetTransform = (e) => {
	const $image = e.target.closest('[data-parallax]');
	const $parallaxImage = $image.querySelector('[data-parallax-image]');
	const $parallaxBorder = $image.querySelector('[data-parallax-border]');
	if ($parallaxImage) $parallaxImage.style.transform = '';
	if ($parallaxBorder) $parallaxBorder.style.transform = '';
};

const showCookiesBanner = (banner) => {
	const bannerStatus = localStorage.getItem('cookies-banner');
	if (bannerStatus === 'off') {
		banner.style.display = 'none';
	}
};

const replaceWebpBg = (backgrounds) => {
	backgrounds.forEach(bg => {
		
		if (bg.hasAttribute('data-fallback-bg')) {
			const intervalID = setInterval(checkWebp, 1000);

			setTimeout(() => {
				clearInterval(intervalID);
			}, 5000);

			function checkWebp () {
				if ($html.classList.contains('webp') || $html.classList.contains('no-webp')) {
					clearInterval(intervalID);
					
					if ($html.classList.contains('no-webp')) {
						bg.style.backgroundImage = `url(${bg.dataset.fallbackBg})`;
					}
				}
			}
		}
	});
};

const onResize = () => {
	if (bodyLockStatus) {
		bodyUnlock(0);
	}
};

const setDeliveryHideContent = () => {
	const $deliveryOptions = document.querySelector('[data-delivery-options]');

	if($deliveryOptions) {
		const $businessRadio = $deliveryOptions.querySelector('[data-delivery-radio="business"]');
		const $personRadio = $deliveryOptions.querySelector('[data-delivery-radio="person"]');
		const $businessActions = $deliveryOptions.querySelectorAll('[data-delivery-action="business"]');
		const $personAction = $deliveryOptions.querySelector('[data-delivery-action="person"]');

		if ($businessRadio && $personRadio && $businessActions && $personAction) {
			if($businessRadio.checked) {
				$personAction.style.display = 'none';
				$businessActions.forEach((el, idx) => idx !== 0 ? el.style.display = 'none' : el.style.display = '');
			}
			if($personRadio.checked) $businessActions.forEach(el => el.style.display = 'none');

			$businessRadio.addEventListener('change', () => {
				$personAction.style.display = 'none';

				if($businessRadio.checked) {
					$businessActions[0].style.display = '';
				}
			});
			$personRadio.addEventListener('change', () => {
				$businessActions.length > 0 && $businessActions.forEach(el => el.style.display = 'none');

				if($personRadio.checked) {
					$personAction.style.display = '';
				}
			});
		}
	}
};

const setRadioHideContent = () => {
	const $radioInputs = document.querySelectorAll('input[data-radio-input]');

	for(let i = 0; i < $radioInputs.length; i++) {
		const $input = document.querySelector(`input[data-radio-input='${i}']`);
		const $content = document.querySelector(`[data-radio-content='${i}']`);
		const $rootOptions = $input && $input.closest('[data-radio-options]');
		const $allContents = $rootOptions && $rootOptions.querySelectorAll('[data-radio-content]');

		if(!$input.checked) {
			if($content) $content.style.display = 'none';
		}

		$input && $input.addEventListener('change', () => {
			$allContents.length > 0 && $allContents.forEach(el => el.style.display = 'none');
			if($input.checked) {
				if($content) $content.style.display = '';
			}
		});
	}
};

const setNotEmptyFields = () => {
	const notEmpty = document.querySelectorAll('input[data-not-empty-input]');

	for(let i = 0; i < notEmpty.length; i++) {
		const $input = document.querySelector(`input[data-not-empty-input='${i}']`);
		const $button = document.querySelector(`button[data-not-empty-button='${i}']`);

		const toggleDisabled = () => {
			if($input.value) {
				$button.classList.remove('_disabled');
			} else {
				$button.classList.add('_disabled');
			}
		}

		$input.addEventListener('input', toggleDisabled);
		$input.addEventListener('focusout', toggleDisabled);
	}
};

const hideDeliveryBusiness = () => {
	$businessDeliveryActions.length > 0 && $businessDeliveryActions.forEach(el => el.style.display = 'none');
};

const setAlternativeDeliveryAddress = () => {
	if ($deliveryAlternativeCheckbox && $deliveryAlternative) {
		if($deliveryAlternativeCheckbox.checked) {
			hideDeliveryBusiness();
			showElement($deliveryAlternative);
		}
		$deliveryAlternativeCheckbox.addEventListener('change', () => {
			hideDeliveryBusiness();
			showElement($deliveryAlternative);
		});
	}
};

const showElement = (el) => {
	if (el) el.style.display = '';
};

const createCheckout = (e) => {
	const targetElement = e.target;

	const $deliveryFormBtn = isTarget(targetElement, '[data-delivery-form-btn]');
	if ($deliveryFormBtn) {
		hideDeliveryBusiness();
		showElement($businessDeliveryActions[0]);
		$deliveryAlternativeCheckbox.checked = false;
	}

	const $deliveryPreviewBtn = isTarget(targetElement, '[data-delivery-preview-btn]');
	if ($deliveryPreviewBtn) {
		hideDeliveryBusiness();
		showElement($dataDeliveryPreview);
		$deliveryAlternativeCheckbox.checked = false;
	}

	const $deliveryEditBtn = isTarget(targetElement, '[data-delivery-edit-btn]');
	if ($deliveryEditBtn) {
		hideDeliveryBusiness();
		showElement($dataDeliveryEdit);
		$deliveryAlternativeCheckbox.checked = false;
	}

	const $deliveryEditCloseBtn = isTarget(targetElement, '[data-delivery-edit-close]');
	if ($deliveryEditCloseBtn) {
		hideDeliveryBusiness();
		showElement($dataDeliveryPreview);
		$deliveryAlternativeCheckbox.checked = false;
	}

	const $deliveryAlternativeCloseBtn = isTarget(targetElement, '[data-delivery-alternative-close]');
	if ($deliveryAlternativeCloseBtn) {
		hideDeliveryBusiness();
		showElement($dataDeliveryPreview);
		$deliveryAlternativeCheckbox.checked = false;
	}

	const $deliveryAlternativeBtn = isTarget(targetElement, 'button[data-delivery-alternative-btn]');
	if ($deliveryAlternativeBtn) {
		hideDeliveryBusiness();
		showElement($deliveryAlternative);
		$deliveryAlternativeCheckbox.checked = true;
	}
};

const init = () => {
	const $html = document.documentElement;
	const $searchForm = document.querySelector('[data-search]');
	const $parallaxItems = document.querySelectorAll('[data-parallax]');
	const $quantities = document.querySelectorAll('.quantity');
	const $webpBgs = document.querySelectorAll('[data-bg$=".webp"]');
	const $checkout = document.querySelector('[data-checkout]');

	$html.classList.add('loaded');
	
	isWebp();
	spollers();
	setCopyrightYear(document.querySelector('.js-copyright-year'));
	formFieldsInit();
	formSubmit(true);
	tabs();

	// checkout
	setNotEmptyFields();
	setRadioHideContent();
	setDeliveryHideContent();
	setAlternativeDeliveryAddress();
	//

	if ($searchForm) {
		searchProducts($searchForm);
	}
	if ($quantities) {
		$quantities.forEach(quantity => quantity.classList.add('_disabled'));
	}
	if ($webpBgs.length) {
		replaceWebpBg($webpBgs);
	}
	if ($cookies) {
		showCookiesBanner($cookies);
	}

	$parallaxItems.forEach(image => image.addEventListener('mousemove', setParallaxOnImage));
	$parallaxItems.forEach(image => image.addEventListener('mouseout', resetTransform));
	
	document.addEventListener('click', clickOnDocument);
	// checkout
	$checkout && $checkout.addEventListener('click', createCheckout);
	//
	window.addEventListener('scroll', setWindowScroll);
	window.addEventListener('resize', onResize);
};

window.addEventListener('load', init);